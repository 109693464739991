import * as utilities from "../src/utilities";
import * as storage from "../src/storage";
import * as appData from "../src/appData";
import * as stash from "../src/stash";
import * as config from "../src/config";
import * as xdm from "../src/xdm";

let findNode = function ( nodeName, object ) {

	return ( typeof ( object[ nodeName ] ) !== 'undefined' ? true : false );

};

let getNode = function ( nodeName, object ) {

	return ( typeof ( object[ nodeName ] ) !== 'undefined' ? object[ nodeName ] : {} )

};

let formatKey = function ( primaryNode, secondaryNode ) {

	let result = "";

	if ( primaryNode ) {
		result = primaryNode + '*';
	}

	result = result + secondaryNode;
	result = result.toLowerCase().replace( / /g, '_' );

	return result;

};

let getEventName = function ( evtObject ) {

	let eventName = evtObject ? evtObject.eventInfo ? evtObject.eventInfo.eventName : '' : '';
	let primaryCategory = evtObject ? evtObject.category ? evtObject.category.primaryCategory : '' : '';

	return formatKey( primaryCategory, eventName );

};

let setRunUniversal = function ( instructions ) {

	if ( instructions.secondary.found ) {

		return instructions.secondary.node.runUniversal;

	}

	if ( instructions.primary.found ) {

		return instructions.primary.node.runUniversal;

	}

	return true;

};

let setRunFinal = function ( instructions ) {

	if ( instructions.secondary.found && typeof instructions.secondary.node.runFinal !== 'undefined' ) {

		return instructions.secondary.node.runFinal;

	}

	if ( instructions.primary.found && typeof instructions.primary.node.runFinal !== 'undefined' ) {

		return instructions.primary.node.runFinal;

	}

	return true;

};

let setReportingType = function ( instructions, eventIndex ) {

	let result = instructions.universalNode.reportingType;

	if ( instructions.primary.found && instructions.primary.node.reportingType ) {

		result = instructions.primary.node.reportingType;

	}

	if ( instructions.secondary.found && instructions.secondary.node.reportingType ) {

		result = instructions.secondary.node.reportingType;

	}

	/***
	* 159862333 - Handles the scenario where an invalid event (meaning one with no matched primary or secondary node) is pushed to the system.  In general
	* our diuplicate suppression logic catches this, but the edge cases where an intemediary page (such as an overlay) precedes the
	* invalid event, thus preventing duplicate suppression from taking place.  Another factor in this is that our default reporting type is 'T', which causes invalid events to be seen as T calls which puts them into
	* a separate path of logic.
	***/
	if ( ( typeof (eventIndex) !== 'undefined' ) && !instructions.primary.found && !instructions.secondary.found ) {

		result = "invalid";

	}

	return result;

};

let setForcePageView = function ( instructions ) {

	if ( instructions.secondary.found && typeof instructions.secondary.node.forcePageView !== 'undefined' ) {

		return instructions.secondary.node.forcePageView;

	}

	if ( instructions.primary.found && typeof instructions.primary.node.forcePageView !== 'undefined' ) {

		return instructions.primary.node.forcePageView;

	}

	return false;

};

let setTrackLinkName = function ( instructions, eventIndex, ddo ) {

	if ( instructions.secondary.found && instructions.secondary.node.trackLinkName ) {

		return ( typeof instructions.secondary.node.trackLinkName === 'function' ? instructions.secondary.node.trackLinkName( ddo, eventIndex ) : instructions.secondary.node.trackLinkName );

	}

	if ( instructions.primary.found && instructions.primary.node.trackLinkName ) {

		return ( typeof instructions.primary.node.trackLinkName === 'function' ? instructions.primary.node.trackLinkName( ddo, eventIndex ) : instructions.primary.node.trackLinkName );

	}

	return "";

};

let Instructions = function ( map, primaryNode, secondaryNode, eventIndex, ddo ) {

	// If new isn't used, a proper instance of the object is created by calling the constructor again with new keyword creating a scope-safe version of the constructor.
	// Although unlikely, this guards against mistakenly calling this constructor function without the new operator. 
	if ( !( this instanceof Instructions ) ) {
		return new Instructions( map, primaryNode, secondaryNode, eventIndex, ddo );
	}

	this.formattedKey = formatKey( primaryNode, secondaryNode );
	this.universalNode = getNode( 'universal', map );
	this.preloads = [];

	this.primary = {
		node: getNode( primaryNode, map ),
		found: findNode( primaryNode, map )
	};

	this.secondary = {
		node: getNode( this.formattedKey, map ),
		found: findNode( this.formattedKey, map )
	};

	this.runUniversal = setRunUniversal( this );
	this.runFinal = setRunFinal( this );
	this.reportingType = setReportingType( this, eventIndex );
	this.forcePageView = setForcePageView( this );
	this.reportTypeNode = getNode( this.reportingType.toLowerCase(), map );
	this.trackLinkName = setTrackLinkName( this, eventIndex, ddo );

};

let setPreloads = function ( map, preloadEventIndexes, originalEvents, pageViewSent ) {

	let result = [];
	let eventName, originalIndex, node;

	for ( let i = 0; i < preloadEventIndexes.length; i += 1 ) {

		originalIndex = preloadEventIndexes[ i ];

		eventName = getEventName( originalEvents[ originalIndex ] );

		if ( ( !pageViewSent || ( pageViewSent && eventName !== "browse*initial_load_browse" && eventName !== "search*initial_load_search" ) ) && findNode( eventName, map ) ) {

			node = getNode( eventName, map );
			node.originalIndex = originalIndex;

			result.push( node );

			//add any matched preloads to prop55
			_aape.metrics.prop55 = utilities.apl( "pl:" + eventName, _aape.metrics.prop55 );
		}

	}

	return result;

};

let track = async function ( metrics ) {

	storage.setItem( "s_pv_pName", metrics.pageName );
	storage.setItem( "s_pv_pType", metrics.prop30 );
	storage.setItem( "s_pv_cmpgn", metrics.campaign );
	metrics.prop33 ? storage.setItem( "s_pv_pVer", metrics.prop33 ) : storage.removeItem( "s_pv_pVer" );

	let xdmData = await xdm.prepareData( metrics );

	console.log( LOG_PREFIX + " -- Page View: xdmData: ", xdmData );

	// Optional: If you’d like, you can also generate a UUID for the _id field and a timestamp manually for inclusion in the `data` object. 
	xdmData._id = utilities.getUUID();
	xdmData._timestamp = new Date();
	xdmData.web.webPageDetails.pageViews = { 'value': 1 };

	let beaconType = "t";
	let trigger = metrics.prop55;
	let ddoInstanceID = metrics.prop57;
	let ddoInstance = appData.getDDOInstance( ddoInstanceID );
	let timestamp = Date();

	_aape.PUB_SUB.publish( "thdcoreanalytics|beacon", {
		"timestamp": timestamp,
		"beaconType": beaconType,
		"trigger": trigger,
		"data": xdmData,
		"ddo": ddoInstance,
		"pageName": ddoInstance.page.pageInfo.pageName
	} );

	window.alloy( "sendEvent", {
		"documentUnloading": true,
		"xdm": xdmData,
		edgeConfigOverrides: {
			com_adobe_analytics: {
				reportSuites: _aape.metrics.reportSuiteOverrides
			}
		}
	} ).catch( function( error ) {
		// The command failed.
		console.log( LOG_PREFIX + " -- ALLOY sendEvent ERROR: ", error );
	} );

};

let trackLink = async function( metrics, name, type, URL ) {

	/*
	The link type argument is a single - character string that determines the type of link tracking call. There are three valid values.
	o: The link is a Custom link.
	d: The link is a Download link.
	e: The link is an Exit link.
	*/

	let xdmData = await xdm.prepareData( metrics, "tl" );

	// Optional: If you’d like, you can also generate a UUID for the _id field and a timestamp manually for inclusion in the `data` object. 
	xdmData._id = utilities.getUUID();
	xdmData._timestamp = new Date();
	
	xdmData.web.webInteraction = {
		"linkClicks": {
			"value": 1
		},
		"name": name, // Name that shows up in the custom links report
		"URL": URL, // The URL of the link
		"type": type // values: other, download, exit

	}

	console.log( LOG_PREFIX + " -- track link: xdmData ", xdmData );

	let beaconType = "tl";
	let trigger = metrics.prop55;
	let ddoInstanceID = metrics.prop57;
	let ddoInstance = appData.getDDOInstance( ddoInstanceID );
	let timestamp = Date();

	_aape.PUB_SUB.publish( "thdcoreanalytics|beacon", {
		"timestamp": timestamp,
		"beaconType": beaconType,
		"trigger": trigger,
		"data": xdmData,
		"ddo": ddoInstance,
		"pageName": ddoInstance.page.pageInfo.pageName
	} );

	window.alloy( "sendEvent", {
		"documentUnloading": true,
		"xdm": xdmData,
		edgeConfigOverrides: {
			com_adobe_analytics: {
				reportSuites: _aape.metrics.reportSuiteOverrides
			}
		}
	} ).catch( function( error ) {
		// The command failed.
		console.log( LOG_PREFIX + " -- ALLOY sendEvent ERROR: ", error );
	} );

};

let clearedToReport = function ( instructions, ddo, ddoInstanceID ) {

	/* eslint-disable no-debugger */
	// debugger;
	/* eslint-enable */

	if ( ( instructions.reportingType === 'tl' || instructions.forcePageView ) && instructions.reportingType !== "invalid" ) {
		console.log( LOG_PREFIX + " -- clearedToReport: true " );
		return true;
	}
	else {

		let reason = "",
			pagename = ( typeof ddo.page.pageInfo.pageName === "string" ? ddo.page.pageInfo.pageName.toLowerCase() : '' );

		if ( instructions.reportingType === "non-reporting" ) {

			reason = "reportingType is 'non-reporting'";

		}
		else if ( instructions.reportingType === "invalid" ) {

			reason = "reporting type is 'invalid'";

		}
		else if ( utilities.isDuplicate( pagename ) ) {
			
			reason = "isDuplicate() check failed";
			appData.addUpdateCache( "suppressProductImpression", true )
		}
		else if ( appData.getFromCache( "pageCurrentlyBeingProcessed", null ) === pagename ) {

			reason = "secondary duplicate check failed";
			appData.addUpdateCache( "suppressProductImpression", true )

		}

		if ( reason ) {

			console.log( `AAPE LOG [Core] -- clearedToReport: false: ${ instructions.formattedKey } -- ${ reason }` );

			_aape.PUB_SUB.publish( "thdcoreanalytics|logger", {
				"timestamp": Date(),
				"type": "warning",
				"msg": "Reporting Suppressed",
				"details": reason,
				"ddo": appData.getDDOInstance( ddoInstanceID ),
				"trigger": ( instructions.formattedKey ? instructions.formattedKey : "none" )
			} );

			return false;

		}

	}

	return true;

}

let report = async function( ddo, ddoInstanceID, primaryNode, secondaryNode, eventIndex ) {

    try {

		let dapMapResult = await import( /* webpackChunkName: "daMap", webpackPrefetch: true */ './daMap' );
        let map = dapMapResult.nodes;
		// console.log( LOG_PREFIX + " -- daMap is loaded: ", dapMapResult );

        let instructions = new Instructions( map, primaryNode, secondaryNode, eventIndex, ddo );

        if ( clearedToReport( instructions, ddo, ddoInstanceID ) ) {

            if ( instructions.reportingType === 't' && primaryNode !== 'overlay' ) {
                appData.addUpdateCache( "pageCurrentlyBeingProcessed", typeof ddo.page.pageInfo.pageName === "string" ? ddo.page.pageInfo.pageName.toLowerCase() : '' );
            }

            let preloadEvents = appData.getPreloadEvents();

            ddo.indexOfEventTrigger = eventIndex; // In support of things like BloomReach that need to access a specific item in the event array.

			_aape.metrics.list2 = ""; // this is not handled by _aape.metrics.manageVars( "clearVars" )

			_aape.metrics.prop55 = ( instructions.formattedKey ? instructions.formattedKey : "none" ); // providing a default will help identify pages that have no pageLoadEvent (such as the homepage)
			_aape.metrics.prop57 = ddoInstanceID;

            let debuggingData = {
				filterIndicator: LOG_PREFIX,
                prop55: instructions.formattedKey,
                reportingType: instructions.reportingType,
                preloadEvents: preloadEvents.length,
                ddoInstanceID: ddoInstanceID,
                eventIndex: eventIndex
            };

            console.table( debuggingData );

            // if preload, modify page view reporting...
            if ( preloadEvents.length > 0 ) {
                // we only process preloads for page beacons
                if ( instructions.reportingType === "t" ) {
                    instructions.preloads = setPreloads( map, preloadEvents, ddo.event, utilities.isDuplicate( ddo.page.pageInfo.pageName ) );
                    let preloadLength = instructions.preloads.length;

                    if ( preloadLength ) {
                        instructions.runUniversal = instructions.preloads[ preloadLength - 1 ].runUniversal;
                        instructions.forcePageView = instructions.preloads[ preloadLength - 1 ].forcePageView;
                        instructions.reportingType = instructions.preloads[ preloadLength - 1 ].reportingType;
                    }
                }

                appData.clearPreloadEvents();

            }

            if ( instructions.runUniversal ) {
                instructions.universalNode.setMetrics( ddo, eventIndex );
            }

            instructions.reportTypeNode.setMetrics( ddo, eventIndex );

            if ( instructions.primary.found ) {
                instructions.primary.node.setMetrics( ddo, eventIndex );
            }

            if ( instructions.secondary.found ) {
                instructions.secondary.node.setMetrics( ddo, eventIndex );
            }

            if ( instructions.preloads.length > 0 ) {

                for ( let i = 0; i < instructions.preloads.length; i += 1 ) {

                    instructions.preloads[ i ].setMetrics( ddo, instructions.preloads[ i ].originalIndex );

                }

            }

            // TODO: Dynamic variables are case sensitive, and lowercaseAndCleanse is currently lowercase pageName to pagename.
			utilities.lowercaseAndCleanse();
						
			// Set RSID before every beacon
			_aape.metrics.reportSuiteOverrides = config.getReportSuiteOverrides( ddo.site.businessType );

			let clonedMetrics = structuredClone( _aape.metrics );

            if ( instructions.reportingType === 't' ) {

                // Stash to use in TL calls that need their parents metric values
				appData.addUpdateCache( "pageLoadProdString", clonedMetrics.products );
				appData.addUpdateCache( "pageLoadeVar63", clonedMetrics.eVar63 );

				await track( clonedMetrics );

                appData.addUpdateCache( "pageViewSent", true );
				//clear persisted data after page calls 
				stash.clearPersistedData();
            }
            else if ( instructions.reportingType === 'tl' ) {

				trackLink( clonedMetrics, instructions.trackLinkName, 'other', "" );

            }
           
            utilities.clearVars();

        }
        else if ( instructions.reportingType === "non-reporting" ) {

            if ( instructions.primary.found ) {
                instructions.primary.node.setMetrics( ddo, eventIndex );
            }

            if ( instructions.secondary.found ) {
                instructions.secondary.node.setMetrics( ddo, eventIndex );
            }

        }

    } catch ( error ) {
        console.error( LOG_PREFIX + ` -- Error: ${ error }` );
    }

};

export {
	report
};
